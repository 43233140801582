import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import FooterMoutains from '../components/common/FooterMountains';
import Header from '../components/common/Header';
import MultipleTexts from '../components/common/MultipleTexts';
import Push from '../components/common/Push';
import Text from '../components/common/Text';
import VerticalLine from '../components/common/VerticalLine';
import VerticalSteps from '../components/common/VerticalSteps';
import FlexibleContent from '../components/FlexibleContent';
import LayoutCt from '../components/layout';
import SEO from '../components/SEO/SEO';

const Layout = styled(LayoutCt)`
    .intro {
        z-index: 3;
    }
`;

export const query = graphql`
    query($lang: WpLanguageCodeEnum) {
        allWpPage(
            filter: {
                language: { code: { eq: $lang } }
                template: { templateName: { eq: "Wealth Management" } }
            }
        ) {
            edges {
                node {
                    seo {
                        description: metaDesc
                        title
                    }
                    acfPageWealth_management {
                        flexible_content {
                            acf_fc_layout
                            double_column_text {
                                images {
                                    image_desktop {
                                        filesize
                                        height
                                        mime_type
                                        title
                                        url
                                        width
                                    }
                                    image_mobile {
                                        filesize
                                        height
                                        mime_type
                                        title
                                        url
                                        width
                                    }
                                }
                                label
                                link {
                                    label
                                    page
                                    target
                                    type
                                    url
                                }
                                position_image
                                text_left
                                text_right
                                title
                            }
                            key_numbers {
                                label
                                list_number {
                                    legend
                                    number
                                }
                            }
                            list_push {
                                label
                                array_push {
                                    title
                                }
                                link {
                                    label
                                    page
                                    target
                                    type
                                    url
                                }
                            }
                            multiple_texts {
                                label
                                images {
                                    image_desktop {
                                        filesize
                                        height
                                        mime_type
                                        title
                                        url
                                        width
                                    }
                                    image_mobile {
                                        filesize
                                        height
                                        mime_type
                                        title
                                        url
                                        width
                                    }
                                }
                                contents {
                                    content
                                }
                            }
                            pie_chart {
                                parts {
                                    list_items {
                                        item
                                    }
                                    title
                                    value_max
                                    value_min
                                }
                            }
                            push {
                                link {
                                    label
                                    page
                                    target
                                    type
                                    url
                                }
                            }
                            text {
                                label
                                text
                                link {
                                    label
                                    page
                                    target
                                    type
                                    url
                                }
                            }
                            triple_column_text {
                                title
                                block_texts {
                                    text
                                    title
                                }
                            }
                            triple_title {
                                text
                                titles {
                                    title
                                }
                            }
                            vertical_steps {
                                label
                                text
                                steps {
                                    step
                                }
                            }
                        }
                        header {
                            subtitle
                            title
                            type
                        }
                        multiple_texts_1 {
                            label
                            images {
                                image_desktop {
                                    filesize
                                    height
                                    mime_type
                                    title
                                    url
                                    width
                                }
                                image_mobile {
                                    filesize
                                    height
                                    mime_type
                                    title
                                    url
                                    width
                                }
                            }
                            contents {
                                content
                            }
                        }
                        multiple_texts_2 {
                            contents {
                                content
                            }
                            images {
                                image_desktop {
                                    filesize
                                    height
                                    mime_type
                                    title
                                    url
                                    width
                                }
                                image_mobile {
                                    filesize
                                    height
                                    mime_type
                                    title
                                    url
                                    width
                                }
                            }
                            label
                        }
                        push {
                            label
                            link {
                                label
                                page
                                target
                                type
                                url
                            }
                        }
                        text {
                            label
                            text
                            link {
                                label
                                page
                                target
                                type
                                url
                            }
                        }
                        vertical_steps {
                            label
                            text
                            steps {
                                step
                            }
                        }
                    }
                }
            }
        }
    }
`;

const WealthManagement = ({ data, pageContext }) => {
    const seo = data.allWpPage.edges[0].node.seo;
    data = data.allWpPage.edges[0].node.acfPageWealth_management;

    return (
        <Layout
            lang={pageContext.lang}
            pathTranslatePage={pageContext.pathTranslatePage}
        >
            <SEO
                lang={seo.lang}
                title={seo.title}
                description={seo.description}
                translations={pageContext.translations}
            />
            <Header className="header_wealth" data={data.header} />
            <MultipleTexts
                className="intro alpha"
                alpha={true}
                data={data.multiple_texts_1}
                id="wealth-management"
            />
            <MultipleTexts data={data.multiple_texts_2} />
            <VerticalSteps
                data={data.vertical_steps}
                id="sport-entertainment"
            />
            <Text data={data.text} />
            <FlexibleContent data={data} />
            <VerticalLine />
            <Push data={data.push} />
            <FooterMoutains />
        </Layout>
    );
};

export default WealthManagement;
